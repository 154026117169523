<template>
  <div>
    <div class="formgrid grid">
      <div class="field col-12">
        <DataTable
          v-model:selection="selected"
          v-model:filters="filters"
          :value="list_po"
          :globalFilterFields="['rcv_no', 'item_name', 'item_sku']"
          dataKey="id"
          responsiveLayout="scroll"
        >
          <template #header>
            <div class="flex justify-content-end">
              <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText
                  v-model="filters['global'].value"
                  placeholder="Filter item"
                />
              </span>
            </div>
          </template>
          <Column selectionMode="multiple" headerStyle="width: 3em" />
          <Column
            field="rcv_no"
            header="No Penerimaan"
            style="min-width: 15rem"
          />
          <Column
            field="pr_no"
            header="NO Pembelian"
            style="min-width: 15rem"
          />
          <Column field="item_sku" header="SKU" style="min-width: 8rem" />
          <Column
            field="item_barcode_1"
            header="Barcode"
            style="min-width: 8rem"
          />
          <Column
            field="item_name"
            header="Deskripsi"
            style="min-width: 12rem"
          />

          <Column field="unit_b" header="Satuan" style="min-width: 6rem">
            <template #body="slotProps">
              {{ slotProps.data.unit_b }}.{{ slotProps.data.unit_s }}
            </template>
          </Column>
          <Column
            field="ubox"
            header="ISI"
            style="min-width: 6rem; justify-content: flex-end"
          />
          <Column
            field="qty_b"
            header="qty (b)"
            style="min-width: 6rem; justify-content: flex-end"
          />
          <Column
            field="qty_s"
            header="qty (s)"
            style="min-width: 6rem; justify-content: flex-end"
          />
          <Column
            field="qty"
            header="QTY (PCS)"
            style="min-width: 8rem; justify-content: flex-end"
          >
          </Column>

          <Column field="cost" header="Harga" style="min-width: 10rem">
            <template #body="slotProps">
              {{ formatCurrency(slotProps.data.cost) }}
            </template>
          </Column>
          <Column
            field="cost_per"
            header="PER"
            style="min-width: 5rem; justify-content: flex-start"
          >
          </Column>

          <Column field="disc_reg" header="DISC.REG" style="min-width: 9rem">
            <template #body="slotProps">
              {{
                slotProps.data.disc_reg > 100
                  ? formatCurrency(slotProps.data.disc_reg)
                  : `${slotProps.data.disc_reg || 0}%`
              }}
            </template>
          </Column>
          <Column field="disc_ext" header="DISC.EXT" style="min-width: 8rem">
            <template #body="slotProps">
              {{
                slotProps.data.disc_ext > 100
                  ? formatCurrency(slotProps.data.disc_ext || 0)
                  : `${slotProps.data.disc_ext || 0}%`
              }}
            </template>
          </Column>
          <Column
            field="bruto"
            header="Jumlah"
            style="min-width: 12rem; max-width: 12rem"
          >
            <template #body="slotProps">
              {{ formatCurrency(slotProps.data.bruto) }}
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
    <div class="flex justify-content-end align-items-end">
      <Button
        label="Tutup"
        icon="pi pi-times"
        class="p-button-text mr-2"
        @click="$emit('close')"
      />
      <Button
        :disabled="disabledSave"
        label="Tambahkan"
        icon="pi pi-check"
        @click="add"
      />
    </div>
  </div>
</template>

<script>
import { formatCurrency } from '@/helpers'
import { FilterMatchMode, FilterOperator } from 'primevue/api'

export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    purchaseOrder: {
      type: Array,
      default() {
        return []
      },
    },
    excludeList: {
      type: Array,
      default() {
        return []
      },
    },
    editMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selected: null,
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        rcv_no: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
      },
      disabledSave: true,
    }
  },
  watch: {
    selected(val) {
      if (val && val.length > 0) {
        this.disabledSave = false
      } else {
        this.disabledSave = true
      }
    },
  },
  computed: {
    list_po() {
      let items = []
      this.purchaseOrder.forEach((po) => {
        po.details.forEach((el) => {
          el.rcv_no = po.rcv_no
          el.rcv_id = po.id
          el.rcvd_id = el.id
          items.push(el)
        })
      })
      const result = items.slice()
      // Tampilkan data item hanya yang belum dipilih
      return result.filter((item) => {
        return this.excludeList.indexOf(`${item.rcv_id}${item.rcvd_id}`) === -1
      })
    },
  },
  methods: {
    add() {
      this.$emit('save', this.selected)
    },
    formatCurrency(value) {
      value = value ? value : 0
      return formatCurrency(value, 0, 0)
    },
  },
}
</script>
