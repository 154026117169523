<template>
  <div>
    <div class="mb-4 flex justify-content-between align-items-center">
      <span class="pr-2 mr-2 font-bold text-2xl">
        Retur - {{ id > 0 ? 'Edit' : 'Tambah' }}
      </span>
      <div>
        <i class="pi pi-home mr-2"></i>
        <span class="font-small">
          / Pembelian / Retur / {{ id > 0 ? 'Edit' : 'Tambah' }}
        </span>
      </div>
    </div>
    <form @submit.prevent="save(!v$.$invalid)">
      <div class="card bg-white">
        <div class="flex justify-content-end mb-2">
          <Button
            :loading="isLoadingSave"
            icon="pi pi-save"
            class="mr-2"
            :label="id > 0 ? 'Update' : 'Simpan'"
            type="submit"
          />
          <Button
            :disabled="old_status_value === 'done'"
            icon="pi pi-plus"
            class="p-button-outlined mr-2"
            label="Item"
            @click="onAddData"
          />
          <Button
            class="p-button-link"
            label="Kembali"
            @click="$router.push({ name: 'PurchaseRetur' })"
          />
        </div>
        <div class="grid">
          <div class="col-12 md:col-8">
            <div class="formgrid grid">
              <div class="field col-12 md:col-4">
                <label>Tgl Retur</label>
                <Calendar
                  v-model="form.rt_at"
                  dateFormat="dd/mm/yy"
                  class="w-full"
                  input-class="w-full"
                />
              </div>
              <div class="field col-12 md:col-4">
                <label>No Retur</label>
                <InputText
                  v-model="form.rt_no"
                  class="w-full"
                  input-class="w-full"
                  maxlength="50"
                  disabled
                />
              </div>
              <div class="field col-12 md:col-4">
                <label>Ref No</label>
                <InputText
                  v-model="form.ref_no"
                  class="w-full"
                  input-class="w-full"
                  maxlength="50"
                />
              </div>
              <div class="field col-12 md:col-4">
                <label>Supplier</label>
                <Dropdown
                  ref="supplierDropdown"
                  v-model="form.supp_id"
                  :filter="true"
                  :options="list_supplier"
                  class="w-full"
                  input-class="w-full"
                  optionValue="id"
                  optionLabel="name"
                  placeholder="Pilih Supplier"
                  :disabled="id"
                  :class="{
                    'p-invalid': v$.form.supp_id.$invalid && submitted,
                  }"
                />
                <small
                  v-if="
                    (v$.form.supp_id.$invalid && submitted) ||
                    v$.form.supp_id.$pending.$response
                  "
                  class="p-error"
                  >{{ v$.form.supp_id.required.$message }}</small
                >
              </div>
              <div class="field col-12 md:col-4">
                <label>Status</label>
                <Dropdown
                  v-model="form.status"
                  :options="list_status"
                  :disabled="!id || old_status_value === 'done'"
                  optionValue="value"
                  optionLabel="label"
                  optionDisabled="disabled"
                  class="w-full"
                  input-class="w-full"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <fx-table
            :items="form.details"
            :total="form.details.length"
            :loading="isLoading"
            :paginator="false"
            hasFooter
            showGridlines
          >
            <template #columns>
              <Column
                field="rcv_no"
                header="NO Penerimaan"
                style="min-width: 15rem; background-color: #f8f9fa"
              />
              <Column
                field="pr_no"
                header="NO Pembelian"
                style="min-width: 15rem; background-color: #f8f9fa"
              />
              <Column
                field="item_sku"
                header="SKU"
                style="min-width: 8rem; background-color: #f8f9fa"
              />
              <Column
                field="item_barcode_1"
                header="Barcode"
                style="min-width: 8rem; background-color: #f8f9fa"
              />
              <Column
                field="item_name"
                header="Deskripsi"
                style="min-width: 12rem; background-color: #f8f9fa"
              />

              <Column
                field="unit_b"
                header="Satuan"
                style="min-width: 6rem; background-color: #f8f9fa"
              >
                <template #body="slotProps">
                  {{ slotProps.data.unit_b }}.{{ slotProps.data.unit_s }}
                </template>
              </Column>
              <Column
                field="ubox"
                header="ISI"
                style="
                  min-width: 6rem;
                  background-color: #f8f9fa;
                  justify-content: flex-end;
                "
              />
              <Column
                field="qty_b"
                header="qty (b)"
                style="min-width: 6rem; justify-content: flex-end"
              />
              <Column
                field="qty_s"
                header="qty (s)"
                style="min-width: 6rem; justify-content: flex-end"
              />
              <Column
                field="qty"
                header="QTY (PCS)"
                style="
                  min-width: 8rem;
                  background-color: #f8f9fa;
                  justify-content: flex-end;
                "
              >
              </Column>

              <Column
                field="cost"
                header="Harga"
                style="
                  min-width: 10rem;
                  background-color: #f8f9fa;
                  justify-content: flex-end;
                "
              >
                <template #body="slotProps">
                  {{ formatCurrency(slotProps.data.cost) }}
                </template>
              </Column>
              <Column
                field="cost_per"
                header="PER"
                style="
                  min-width: 5rem;
                  background-color: #f8f9fa;
                  justify-content: flex-start;
                "
              >
              </Column>

              <Column
                field="disc_reg"
                header="DISC.REG"
                style="
                  min-width: 8rem;
                  background-color: #f8f9fa;
                  justify-content: flex-end;
                "
              >
                <template #body="slotProps">
                  {{
                    slotProps.data.disc_reg > 100
                      ? formatCurrency(slotProps.data.disc_reg)
                      : `${slotProps.data.disc_reg || 0}%`
                  }}
                </template>
              </Column>
              <Column
                field="disc_ext"
                header="DISC.EXT"
                style="
                  min-width: 8rem;
                  background-color: #f8f9fa;
                  justify-content: flex-end;
                "
              >
                <template #body="slotProps">
                  {{
                    slotProps.data.disc_ext > 100
                      ? formatCurrency(slotProps.data.disc_ext || 0)
                      : `${slotProps.data.disc_ext || 0}%`
                  }}
                </template>
              </Column>
              <Column
                field="dpp"
                header="Jumlah NET (-PPN)"
                style="
                  min-width: 12rem;
                  max-width: 12rem;
                  background-color: #f8f9fa;
                  justify-content: flex-end;
                "
              >
                <template #body="slotProps">
                  {{ formatCurrency(slotProps.data.dpp) }}
                </template>
              </Column>

              <!-- <Column
                field="ppn"
                header="PPN"
                style="
                  min-width: 12rem;
                  max-width: 12rem;
                  background-color: #f8f9fa;
                "
              >
                <template #body="slotProps">
                  {{ formatCurrency(slotProps.data.ppn) }}
                </template>
              </Column> -->

              <Column style="flex-grow: 1; justify-content: flex-end">
                <template #body="{ data }">
                  <Button
                    type="button"
                    icon="pi pi-pencil"
                    class="
                      p-button-outlined p-button-secondary p-button-text
                      mr-2
                    "
                    :disabled="old_status_value === 'done'"
                    @click="onEditData(data)"
                  />
                  <Button
                    type="button"
                    icon="pi pi-trash"
                    :disabled="old_status_value === 'done'"
                    class="p-button-outlined p-button-danger p-button-text"
                    @click="onConfirmDeletion(data)"
                  />
                </template>
              </Column>
            </template>
            <template #footer>
              <table class="flex justify-content-end text-grey-200">
                <tr>
                  <td width="100%">
                    <table class="justify-content-end text-700">
                      <tr>
                        <td>SUB TOTAL</td>
                        <td style="width: 150px" class="text-right">
                          {{ formatCurrency(totalPO.bruto || 0) }}
                        </td>
                      </tr>
                      <tr>
                        <td>DISC REGULER</td>
                        <td class="text-right">
                          {{ formatCurrency(totalPO.disc_reg || 0) }}
                        </td>
                      </tr>
                      <tr>
                        <td>DISC EXTRA</td>
                        <td class="text-right">
                          {{ formatCurrency(totalPO.disc_ext || 0) }}
                        </td>
                      </tr>
                      <tr>
                        <td>PPN</td>
                        <td class="text-right">
                          {{ formatCurrency(totalPO.ppn || 0) }}
                        </td>
                      </tr>
                      <tr>
                        <td>GRAND TOTAL</td>
                        <td class="text-right">
                          {{ formatCurrency(totalPO.total || 0) }}
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </template>
          </fx-table>
        </div>
      </div>
    </form>
    <Dialog
      :header="'Tambah Item Retur'"
      v-model:visible="dialog"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '95vw' }"
      :modal="true"
      :maximizable="true"
    >
      <form-purchase-retur
        :purchaseOrder="list_purchase_order"
        :excludeList="list_produk"
        :editMode="editMode"
        @save="onSaveData"
        @close="dialog = false"
      />
    </Dialog>
    <Dialog
      header="Edit Item Retur"
      v-model:visible="dialogItem"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '40vw' }"
      :modal="true"
      :maximizable="true"
    >
      <form-purchase-order
        :item="formDetail"
        :qtyB="originalQtyB"
        :qtyS="originalQtyS"
        :loading="isLoadingSave"
        :list-satuan="list_satuan"
        :list-items="list_items"
        :purchase-order="PurchaseOrder"
        :editMode="editMode"
        onMenu="retur"
        @save="onSaveDataItem"
        @close="dialogItem = false"
      />
    </Dialog>
    <Dialog
      header="Perubahan Supplier"
      v-model:visible="dialogSupplier"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '40vw' }"
      :modal="true"
      :closable="false"
    >
      <div class="confirmation-content">
        <span>Item yang sudah diinput berbeda supplier, Ulangi Input ?</span>
      </div>
      <template #footer>
        <Button
          label="No"
          icon="pi pi-times"
          class="p-button-text p-button-secondary"
          @click="onNoSupplier(this.old_supplier_id)"
        />
        <Button label="Yes" @click="onYesSupplier" />
      </template>
    </Dialog>
    <Dialog
      header="Hapus Retur"
      v-model:visible="dialogHapus"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      :modal="true"
    >
      <div class="confirmation-content">
        <span
          >Item Retur <strong>{{ formDetail.item_name }}</strong> akan dihapus.
          Proses ?</span
        >
      </div>
      <template #footer>
        <Button
          label="Hapus"
          icon="pi pi-trash"
          @click="onDeleteItemPurchaseOrder"
          class="p-button-text p-button-danger"
        />
      </template>
    </Dialog>
    <hotkey :shortcuts="['D']" @triggered="onTriggerHotkey" />
  </div>
</template>

<script>
import SettingService from '@/services/SettingService'
import ItemService from '@/services/ItemService'
import SatuanService from '@/services/SatuanService'
import TermService from '@/services/TermService'
import PurchaseReceiveService from '@/services/PurchaseReceiveService'
import PurchaseReturService from '@/services/PurchaseReturService'
import SupplierService from '@/services/SupplierService'
import FormPurchaseOrder from '@/components/pembelian/FormPurchaseOrder'
import FormPurchaseRetur from '@/components/pembelian/FormPurchaseRetur'
import FxTable from '@/components/FxTable'
import errorHandler from '@/helpers/error-handler'
import Hotkey from '@/components/Hotkey'
import { FilterMatchMode } from 'primevue/api'
import { formatCurrency, ceiling } from '@/helpers/index'
import useVuelidate from '@vuelidate/core'
import { helpers, required } from '@vuelidate/validators'
import dayjs from 'dayjs'
export default {
  setup: () => ({ v$: useVuelidate() }),
  name: 'PurchaseRetur',
  components: {
    FormPurchaseOrder,
    FormPurchaseRetur,
    FxTable,
    Hotkey,
  },
  data() {
    return {
      id: parseInt(this.$route.params.id),
      submitted: false,
      settingService: null,
      itemService: null,
      satuanService: null,
      termService: null,
      supplierService: null,
      purchaseReceiveService: null,
      purchaseReturService: null,
      dialog: false,
      dialogItem: false,
      dialogHapus: false,
      dialogSupplier: false,
      isLoading: false,
      isLoadingItem: false,
      isLoadingSave: false,
      editMode: false,
      old_status_value: 'draft',
      old_supplier_id: 0,
      items: [],
      list_supplier: [],
      list_term: [],
      list_satuan: [],
      list_items: [],
      list_global_setting: [],
      list_purchase_order: [],
      list_detail_receive: [],
      form: {
        rt_no: '',
        rt_at: new Date(),
        ref_no: '',
        supp_id: '',
        details: [],
        status: 'draft',
      },
      formDetail: {},
      originalQtyB: 0,
      originalQtyS: 0,
      listDeleted: [],
      PurchaseOrder: [],
      containModesOption: [
        { label: 'Contains', value: FilterMatchMode.CONTAINS },
      ],
      equalModesOption: [{ label: 'Equals', value: FilterMatchMode.EQUALS }],
    }
  },
  computed: {
    list_status() {
      const list = [
        { value: 'draft', label: 'Draft', level: 0 },
        { value: 'done', label: 'Done', level: 1 },
      ]
      let old_status_level = 0
      if (this.old_status_value) {
        const status = list.find((e) => e.value === this.old_status_value)
        if (status) old_status_level = status.level
      }
      return list.map((status) => ({
        ...status,
        disabled: old_status_level > status.level,
      }))
    },
    list_produk() {
      return this.form.details.map((x) => `${x.rcv_id}${x.rcvd_id}`)
    },
    totalPO() {
      // Calculate purchase.disc_reg
      let discRegTotal = this.form.details.reduce((total, detail) => {
        total += detail.disc_regv
        return total
      }, 0)

      let discExtTotal = this.form.details.reduce((total, detail) => {
        total += detail.disc_extv
        return total
      }, 0)

      // jumlah bruto
      let bruto = this.form.details.reduce((total, detail) => {
        total += detail.bruto
        return total
      }, 0)

      // Calculate total_dpp
      let dpp = bruto - discRegTotal - discExtTotal

      // Calculate total_ppn
      // let total_ppn = Math.round(dpp * (detail.tax / 100.0) * 100) / 100
      // let total_ppn = this.form.details.reduce((total, detail) => {
      //   total += detail.ppn
      //   return total
      // }, 0)

      // let tax = 0
      // this.form.details.map((item) => {
      //   if (item.tax) {
      //     tax = item.tax
      //   }
      // })

      let total_ppn = this.form.details.reduce((total, detail) => {
        total += Math.round(
          (detail.bruto - detail.disc_regv - detail.disc_extv) *
            (detail.tax / 100)
        )
        return total
      }, 0)

      const total = {
        bruto: bruto,
        dpp: dpp,
        disc_reg: discRegTotal,
        disc_ext: discExtTotal,
        ppn: total_ppn,
        total: dpp + total_ppn,
      }
      return total
    },
  },
  watch: {
    'form.supp_id'(value) {
      if (this.form.details.length > 0) {
        this.purchaseReceiveService
          .getById(`${this.form.details[0].rcv_id}`)
          .then((res) => {
            if (res.data.data.supp_id !== value) {
              this.dialogSupplier = true
              this.old_supplier_id = res.data.data.supp_id
            }
          })
          .catch((err) => {
            errorHandler(err, 'Data Supplier', this)
          })
      }
    },
  },
  created() {
    this.purchaseReceiveService = new PurchaseReceiveService()
    this.purchaseReturService = new PurchaseReturService()
    this.termService = new TermService()
    this.supplierService = new SupplierService()
    this.satuanService = new SatuanService()
    this.itemService = new ItemService()
    this.settingService = new SettingService()
  },
  async mounted() {
    if (this.id > 0) {
      this.loadData()
    }

    this.settingService
      .get()
      .then((res) => {
        this.list_global_setting = res.data
      })
      .catch((err) => {
        errorHandler(err, 'Data Supplier', this)
      })
      .finally(() => (this.isLoading = false))

    this.supplierService
      .getActive()
      .then((res) => {
        this.list_supplier = res.data
      })
      .catch((err) => {
        errorHandler(err, 'Data Supplier', this)
      })
      .finally(() => (this.isLoading = false))

    this.termService
      .get()
      .then((res) => {
        this.list_term = res.data
      })
      .catch((err) => {
        errorHandler(err, 'Data Termin', this)
      })

    this.satuanService
      .get()
      .then((res) => {
        this.list_satuan = res.data.data
      })
      .catch((err) => {
        errorHandler(err, 'Data Satuan', this)
      })

    this.itemService
      .get()
      .then((res) => {
        this.list_items = res.data.data
      })
      .catch((err) => {
        errorHandler(err, 'Data Items', this)
      })
  },
  methods: {
    totalDetail(detail) {
      const item =
        this.list_items.filter((item) => item.id === detail.item_id).length > 0
          ? this.list_items.filter((item) => item.id === detail.item_id)[0]
          : {}

      let qty_b = detail['qty_b']
      let qty_s = detail['qty_s']

      // hitung total qty
      let qty = 0
      if (qty_b > 0) {
        qty = detail['ubox'] * qty_b
      }
      qty += qty_s

      // hitung total bruto dan harga per unit
      let bruto = 0
      if (detail['cost_per'] === detail['unit_b']) {
        bruto = Math.round(detail['cost'] / detail['ubox']) * qty
      } else {
        bruto = Math.round(detail['cost']) * qty
      }

      let cost_s = detail['cost_s']

      //  disc reg
      let disc_regv = Math.round(detail['disc_regv'] / detail['qty'])

      // total diskon reg
      disc_regv *= qty

      //  disc ext
      let disc_extv = Math.round(detail['disc_extv'] / detail['qty'])

      // total diskon ext
      disc_extv *= qty

      // total dpp
      let dpp = cost_s * qty
      let total = dpp

      // price
      let price = Number((cost_s / (1 - item.std_mrg / 100)).toFixed(2))
      let ppn = Math.floor(price * (detail.tax / 100))
      price = ceiling(price + ppn)

      return {
        qty: qty,
        sunit: detail['sunit'],
        qty_b: qty_b,
        qty_s: qty_s,
        cost_per: detail['cost_per'],
        bruto: bruto,
        dpp: dpp,
        // ppn: ppn,
        total: total,
        price: price,
        disc_regv: disc_regv,
        disc_extv: disc_extv,
      }
    },
    save(isFormValid) {
      this.submitted = true

      if (!isFormValid) {
        return
      }

      const deleted = this.id ? { deleted: this.listDeleted } : {}

      const rt_at = this.form.rt_at
        ? { rt_at: dayjs(this.form.rt_at).format('YYYY-MM-DD') }
        : null

      const form = {
        ...rt_at,
        rt_no: this.form.rt_no,
        ref_no: this.form.ref_no,
        supp_id: this.form.supp_id,
        status: this.form.status,
        details: this.form.details.map((item) => {
          const id = item.id ? { id: item.id } : this.id ? { id: 0 } : {}
          return {
            ...id,
            rcvd_id: item.rcvd_id,
            qty_b: item.qty_b,
            qty_s: item.qty_s,
          }
        }),
        ...deleted,
      }

      if (!this.id) {
        this.isLoadingSave = true
        this.purchaseReturService
          .add(form)
          .then((res) => {
            if (res.data.status === 1) {
              this.$toast.add({
                severity: 'success',
                summary: 'Retur',
                detail: 'Data berhasil disimpan.',
                life: 3000,
              })

              this.$router.push({ name: 'PurchaseRetur' })
            }
          })
          .catch((err) => {
            errorHandler(err, 'Retur', this)
          })
          .finally(() => {
            this.deleted = []
            this.isLoadingSave = false
          })
      } else {
        this.isLoadingSave = true
        this.purchaseReturService
          .update(this.id, form)
          .then((res) => {
            if (res.data.status === 1) {
              this.loadData()
              this.$toast.add({
                severity: 'success',
                summary: 'Retur',
                detail: 'Data berhasil diupdate.',
                life: 3000,
              })
              if (this.form.status === 'done') {
                this.$router.push({ name: 'PurchaseRetur' })
              }
            }
          })
          .catch((err) => {
            errorHandler(err, 'Retur', this)
          })
          .finally(() => {
            this.isLoadingSave = false
          })
      }
    },
    formatCurrency(val) {
      return formatCurrency(val, 0, 0)
    },

    loadData() {
      this.isLoading = true
      this.purchaseReturService
        .getById(this.id)
        .then((res) => {
          this.form = res.data.data
          this.form.rt_at = this.form.rt_at ? new Date(this.form.rt_at) : ''
          this.old_status_value = this.form.status
          this.isLoading = false
        })
        .catch((err) => {
          errorHandler(err, 'Data Retur', this)
        })
        .finally(() => (this.isLoading = false))
    },

    onEditData(data) {
      this.editMode = true
      this.formDetail = Object.assign({}, data)
      this.originalQtyB = data.qty_b
      this.originalQtyS = data.qty_s
      // this.purchaseReceiveService
      //   .getById(this.formDetail.pr_id)
      //   .then((res) => {
      //     this.formDetail.tax = res.data.data.tax
      //     this.list_detail_receive = res.data.data.details

      //     const idx = this.list_detail_receive.findIndex(
      //       (item) => item.item_id === this.formDetail.item_id
      //     )
      //     if (idx !== -1) {
      //       this.list_satuan_unit = this.list_satuan.filter(
      //         (item) =>
      //           item.sat === 'PCS' ||
      //           item.sat === this.list_detail_receive[idx].unit
      //       )
      //     } else {
      //       this.list_satuan_unit = this.list_satuan
      //     }
      //     this.dialogItem = true
      //     this.isLoading = false
      //   })
      //   .catch(() => {
      //     // this.isLoadingItem = false
      //   })
      //   .finally(() => (this.isLoading = false))
      this.dialogItem = true
    },
    onAddData() {
      this.editMode = false

      if (!this.form.supp_id) {
        this.$toast.add({
          severity: 'warn',
          summary: 'Retur',
          detail: 'Supplier harus diisi terlebih dahulu.',
          life: 3000,
        })
        return
      }

      const supplier = this.list_supplier.filter(
        (item) => item.id === this.form.supp_id
      )[0]

      this.isLoadingItem = true
      this.purchaseReceiveService
        .getBy(`?status=printed,posted&supp_id=${this.form.supp_id}&return=0`)
        .then((res) => {
          if (!res.data.data || res.data.data.length === 0) {
            this.$toast.add({
              severity: 'warn',
              summary: 'Retur',
              detail: `Retur untuk pelanggan ${supplier.name} tidak ditemukan!`,
              life: 3000,
            })
            this.isLoadingItem = false
          } else {
            this.list_purchase_order = res.data.data
            this.dialog = true
            this.isLoadingItem = false
          }
        })
        .catch(() => {
          this.isLoadingItem = false
        })
    },
    async onSaveDataItem(data) {
      const detailItem = Object.assign({}, data)

      const idx = this.form.details.findIndex(
        (item) =>
          item.rcvd_id === detailItem.rcvd_id &&
          item.rcv_id === detailItem.rcv_id
      )

      const totalDetail = await this.totalDetail(detailItem)
      if (this.editMode) {
        Object.assign(this.form.details[idx], { ...detailItem, ...totalDetail })
        this.dialogItem = false
      }
    },
    async onSaveData(data) {
      data.map(async (detailItem) => {
        const idx = this.form.details.findIndex(
          (item) =>
            item.rcvd_id === detailItem.rcvd_id &&
            item.rcv_id === detailItem.rcv_id
        )
        const totalDetail = await this.totalDetail(detailItem)
        if (idx === -1) {
          delete detailItem.id
          this.form.details.push({ ...detailItem, ...totalDetail })
          this.$toast.add({
            severity: 'success',
            summary: 'Retur',
            detail: 'Data Item berhasil ditambahkan.',
            life: 3000,
          })
          this.dialog = false
        } else {
          this.$toast.add({
            severity: 'error',
            summary: 'Retur',
            detail: `Item ${detailItem.item_name} dengan No Pembelian ${detailItem.rcv_no} sudah ada`,
            life: 3000,
          })
        }
      })
    },
    onConfirmDeletion(data) {
      this.formDetail = data
      this.dialogHapus = true
    },
    onDeleteItemPurchaseOrder() {
      const index = this.form.details.findIndex(
        (el) => el.item_id === this.formDetail.item_id
      )
      if (index !== -1) {
        if (this.id && this.form.details[index].id) {
          this.listDeleted.push(this.form.details[index].id)
        }
        this.form.details.splice(index, 1)
        this.$toast.add({
          severity: 'success',
          summary: 'Data Item Retur',
          detail: 'Data berhasil dihapus.',
          life: 3000,
        })
      }
      this.dialogHapus = false
    },
    onYesSupplier() {
      this.form.details = []
      this.dialogSupplier = false
    },
    onNoSupplier(value) {
      this.$refs.supplierDropdown.filterValue = ''
      this.form.supp_id = value
      this.dialogSupplier = false
    },
    onTriggerHotkey(payload) {
      if (payload.keyString === 'D') {
        this.onAddData()
      }
    },
    /* onToggle(value) {
      this.selectedColumns = this.columns.filter((col) => value.includes(col))
    }, */
  },
  validations() {
    return {
      form: {
        supp_id: {
          required: helpers.withMessage('Supplier harus diisi.', required),
        },
      },
    }
  },
}
</script>
