import http from '../clients/Axios'

export default class PurchaseReturService {
  async paginate(pageNumber, pageSize = 10, sortField, sortOrder, filters) {
    pageNumber = pageNumber === undefined ? 1 : pageNumber
    const sort = sortOrder === 1 ? '-' + sortField : sortField

    let filters_param = ''

    if (filters) {
      const keys = Object.keys(filters)

      keys.forEach((el) => {
        if (filters[el].value) {
          if (el === 'ref_no' || el === 'rt_no') {
            filters_param =
              filters_param + '&' + el + '=' + filters[el].value.trim()
          } else {
            filters_param = filters_param + '&' + el + '=' + filters[el].value
          }
        }
      })
    }

    const res = await http.get(
      `/purchase/return/paginate?page=${pageNumber}&size=${pageSize}&sort=${sort}${filters_param}`
    )

    return res.data
  }

  async get() {
    const res = await http.get('/purchase/return')
    return res
  }

  async getById(param) {
    param = param ? param : ''
    const res = await http.get(`/purchase/return/${param}`)
    return res
  }

  async add(form) {
    const res = await http.post('/purchase/return', form)
    return res
  }

  async update(id, form) {
    const res = await http.put(`/purchase/return/${id}`, form)
    return res
  }

  async delete(id) {
    const res = await http.delete(`/purchase/return/${id}`)
    return res
  }

  async print(id) {
    const unixTime = Math.floor(Date.now() / 1000)
    return http({
      url: `/purchase/return/${id}/print?t=${unixTime}`,
      method: 'GET',
      responseType: 'blob',
    })
  }
}
